import { SingleSelectOption } from "@components/Select";
import Select from "@components/Select/SingleSelect/Select";
import { HorizonContext } from "@context";
import { SelectChangeEvent } from "@mui/material";
import { useSectors } from "@services/api/horizon";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

const IndustrySelector = () => {
  const { t } = useTranslation();
  const { currentCtx, saveCurrentCtx } = useContext(HorizonContext);

  const { data } = useSectors();

  const handleSectorChange = (event: SelectChangeEvent) => {
    const sectorId = event.target.value;
    saveCurrentCtx({
      ...currentCtx,
      sectorId,
      brandId: "",
      brandEquityCtx: {
        ...currentCtx.brandEquityCtx,
        marketBrandIds: []
      }
    });
  };

  const sectorItems: SingleSelectOption[] = useMemo(() => {
    const sectorsFmt = (data ?? []).map((sector) => {
      return { ...sector, id: sector.id.toString() };
    });
    return sectorsFmt;
  }, [data]);

  return (
    <Select
      id="industry-select"
      variant="outer-label"
      inputLabel={t("horizon.selectors.industry")}
      value={currentCtx.sectorId}
      onChange={handleSectorChange}
      options={sectorItems}
    />
  );
};

export default IndustrySelector;
