import { LineChartData } from "@dive.tv/dss-ui-graphs/LineChart/types";
import LayoutHorizon from "@layouts/LayoutHorizon";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import useSocioeconomics from "@services/api/horizon/Socioeconomics";
import {
  SocioeconomicCategory,
  SocioeconomicCategoryData
} from "@services/api/model/horizon/Socioeconomics";
import { useTranslation } from "react-i18next";
import ChartCard from "./components/ChartCard";

const SocioeconomicEnvironmentView = () => {
  const { t } = useTranslation();
  const { socioeconomicsCategories, socioeconomicsDataQueries } =
    useSocioeconomics();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  if (socioeconomicsCategories.isLoading) {
    return (
      <LayoutHorizon>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
          }}
        >
          <CircularProgress />
        </Box>
      </LayoutHorizon>
    );
  }

  if (socioeconomicsCategories.error) {
    return (
      <LayoutHorizon>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
          }}
        >
          <Typography variant="body1">
            Error loading SocioEconomic Categories:{" "}
            {socioeconomicsCategories.error.message}
          </Typography>
        </Box>
      </LayoutHorizon>
    );
  }

  // If any query is loading
  const isAnyLoading = socioeconomicsDataQueries.some(
    (query) => query.isLoading
  );
  if (isAnyLoading)
    return (
      <LayoutHorizon>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
          }}
        >
          <CircularProgress />
        </Box>
      </LayoutHorizon>
    );

  // If any query has errors
  const isAnyError = socioeconomicsDataQueries.some((query) => query.isError);
  if (isAnyError)
    return (
      <LayoutHorizon>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
          }}
        >
          <Typography variant="body1">
            Error loading SocioEconomic Data by Category
          </Typography>
        </Box>
      </LayoutHorizon>
    );

  const transformTradingEconomicsData = (
    name: string,
    id: number
  ): LineChartData[] => {
    const query = socioeconomicsDataQueries.find(
      (query) =>
        Array.isArray(query.data) &&
        query.data.length > 0 &&
        query.data[0].category_id === id
    );

    // Check whether the query has valid data before continuing, if not return empty []
    if (!query || !Array.isArray(query.data) || query.data.length === 0) {
      return [];
    }

    const timeNow = new Date();

    // Initialise objects of type LineChartData for past and future dates
    const pastData: LineChartData = {
      id: `${name}`,
      color: "#2746f4",
      data: []
    };

    const futureData: LineChartData = {
      id: `${name} Forecast`,
      color: "#a4b1f2",
      data: []
    };

    // Classify the data in past or future
    query.data.forEach((dataset: SocioeconomicCategoryData) => {
      const datasetDate = new Date(dataset.date);

      // Format the date for the x-axis
      const formattedDate = datasetDate.toLocaleDateString("en-ES", {
        month: "short",
        year: "numeric"
      });

      if (datasetDate < timeNow) {
        pastData.data.push({ x: formattedDate, y: dataset.value });
      } else {
        futureData.data.push({ x: formattedDate, y: dataset.value });
      }
    });

    // Create a connection point if there is data both in the past and in the future.
    if (pastData.data.length > 0 && futureData.data.length > 0) {
      const lastPastPoint = pastData.data[pastData.data.length - 1];

      futureData.data.unshift({
        x: lastPastPoint.x,
        y: lastPastPoint.y
      });
    }

    const result: LineChartData[] = [];

    if (pastData.data.length > 0) {
      result.push(pastData);
    }

    if (futureData.data.length > 0) {
      result.push(futureData);
    }

    return result;
  };

  return (
    <LayoutHorizon>
      <Box sx={{ flexGrow: 1, padding: "1rem" }}>
        <Typography variant="h5" component="h1" gutterBottom>
          {t("horizon.tabs.socioeconomic_environment")}
        </Typography>
        <Grid container columnSpacing={isTablet ? 0 : 3} rowSpacing={3}>
          {socioeconomicsCategories.data?.map(
            (socioeconomicCategory: SocioeconomicCategory) => {
              const chartData = transformTradingEconomicsData(
                socioeconomicCategory.name,
                socioeconomicCategory.id
              );
              return (
                <Grid key={socioeconomicCategory.id} item xs={12} lg={6}>
                  <ChartCard
                    category={socioeconomicCategory}
                    data={chartData}
                  />
                </Grid>
              );
            }
          )}
        </Grid>
      </Box>
    </LayoutHorizon>
  );
};

export default SocioeconomicEnvironmentView;
