import Alert, { AlertColor } from "@mui/material/Alert";
import Snackbar, {
  SnackbarCloseReason,
  SnackbarOrigin
} from "@mui/material/Snackbar";

export interface SnackBarProps {
  id: string;
  message: string;
  position?: SnackbarOrigin;
  color: AlertColor;
  autoHideDuration?: number;
  open: boolean;
  handleClose: (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => void;
}

const SnackBar = ({
  id,
  message,
  position = { vertical: "top", horizontal: "right" },
  color,
  autoHideDuration = 3000,
  open,
  handleClose
}: SnackBarProps) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={position}
      key={id}
    >
      <Alert
        onClose={handleClose}
        severity={color}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
