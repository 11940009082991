import HorizonRoutes from "@constants/horizonRouting";
import { Box } from "@mui/material";
import ViewFallback from "@views/ViewFallback/ViewFallback";
import { Route, Routes } from "react-router-dom";
import BrandEquityForecastView from "./views/BrandEquityForecastView";
import FunnelStateView from "./views/FunnelStateView";
import IndustryTrendView from "./views/IndustryTrendView";
import SocioeconomicEnvironmentView from "./views/SocioeconomicEnvironmentView";

const ViewHorizon = () => {
  return (
    <Box>
      <Routes>
        <Route path={HorizonRoutes.Home} Component={IndustryTrendView} />
        <Route
          path={HorizonRoutes.BrandEquity}
          Component={BrandEquityForecastView}
        />
        <Route
          path={HorizonRoutes.Socioeconomic}
          Component={SocioeconomicEnvironmentView}
        />
        <Route path={HorizonRoutes.FunnelState} Component={FunnelStateView} />
        {/* Not found page */}
        <Route path="*" element={<ViewFallback variant="not-found" />} />
      </Routes>
    </Box>
  );
};
export default ViewHorizon;
