/** @jsxImportSource @emotion/react */
import { HorizonContext } from "@context";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useSectors } from "@services/api/horizon";
import { useFunnelStateMetricsExcel } from "@services/api/horizon/FunnelStateMetrics";
import { getExcelDateFmt } from "@utils/ExcelUtils";
import BrandSelector from "@views/ViewHorizon/components/Selectors/BrandSelector";
import IndustrySelector from "@views/ViewHorizon/components/Selectors/IndustrySelector";
import saveAs from "file-saver";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const Selectors = () => {
  const { t } = useTranslation();
  const { currentCtx } = useContext(HorizonContext);

  const [clicked, setClicked] = useState(false);

  const { data: sectors } = useSectors();

  const sectorsById = useMemo(() => {
    const sectorsById: { [key: string]: string } = {};
    (sectors ?? []).forEach((sector) => {
      sectorsById[sector.id.toString()] = sector.label;
    });
    return sectorsById;
  }, [sectors]);

  const brandsById = useMemo(() => {
    const brandsById: { [key: string]: string } = {};
    (sectors ?? []).forEach((sector) => {
      sector.brands.forEach((brand) => {
        brandsById[brand.id.toString()] = brand.label;
      });
    });
    return brandsById;
  }, [sectors]);

  const excelFileNameBySelection = useMemo(() => {
    const sectorName = sectorsById[currentCtx.sectorId] ?? currentCtx.sectorId;
    const brandName = brandsById[currentCtx.brandId] ?? currentCtx.brandId;
    return `Report_Funnel_State_${sectorName}_${brandName}`;
  }, [sectorsById, brandsById, currentCtx.sectorId, currentCtx.brandId]);

  const {
    data: excelBlob,
    refetch: refetchExcel,
    isFetching: isFetchingExcel
  } = useFunnelStateMetricsExcel({
    sectorId: currentCtx.sectorId,
    brandId: currentCtx.brandId
  });

  const isEnabledDownloadReportButton: boolean = useMemo(() => {
    return currentCtx.sectorId.length > 0 && currentCtx.brandId.length > 0;
  }, [currentCtx]);

  useEffect(() => {
    if (excelBlob && clicked) {
      saveAs(
        excelBlob,
        `${excelFileNameBySelection}_${getExcelDateFmt()}.xlsx`
      );
      setClicked(false);
    }
  }, [excelFileNameBySelection, excelBlob, clicked]);

  const handleDownloadReport = () => {
    setClicked(true);
    refetchExcel();
  };

  return (
    <Box sx={{ height: "100%" }}>
      <Paper
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: 2
        }}
        elevation={0}
      >
        <Box>
          <Typography variant="h5" gutterBottom>
            Horizon
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <IndustrySelector />
            <BrandSelector customOpts={[]} />
          </Box>
        </Box>
        <Button
          variant="contained"
          onClick={handleDownloadReport}
          disabled={!isEnabledDownloadReportButton}
          sx={{
            textTransform: "none"
          }}
        >
          {isFetchingExcel && (
            <CircularProgress size="1.5rem" sx={{ color: "white" }} />
          )}
          {!isFetchingExcel && t("horizon.brand_equity.download_forecast")}
        </Button>
      </Paper>
    </Box>
  );
};

export default Selectors;
