import Routes from "@constants/routing";
import ViewFallback from "@views/ViewFallback/ViewFallback";
import ViewHome from "@views/ViewHome";
import ViewHorizon from "@views/ViewHorizon";
import { createBrowserRouter } from "react-router-dom";

const toRoutePathWithMultipleViews = (parentPath: string): string => {
  return `${parentPath}/*`;
};
const router = createBrowserRouter([
  {
    path: Routes.Home,
    element: <ViewHome />
  },
  {
    path: toRoutePathWithMultipleViews(Routes.Horizon),
    element: <ViewHorizon />
  },
  {
    path: "*",
    element: <ViewFallback variant="not-found" />
  }
]);

export default router;
