import GeneralTooltip from "@dive.tv/dss-ui-graphs/Tooltips/GeneralTooltip";
import { PointTooltipProps } from "@nivo/line";

const LineChartTooltip = ({ point }: PointTooltipProps) => {
  const dateTooltip = new Date(point.data.x).toLocaleDateString("en-ES", {
    year: "numeric",
    month: "short"
  });
  return <GeneralTooltip text={`(${dateTooltip} - ${point.data.y})`} />;
};

export default LineChartTooltip;
