import HorizonProvider from "@context/Horizon/HorizonContext";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { StrictMode } from "react";
import { RouterProvider } from "react-router-dom";
import router from "./AppRoutes";
import NewFutureToolsTheme from "./NewFutureToolsTheme";
const queryClient = new QueryClient();

const App = () => {
  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={NewFutureToolsTheme}>
          <CssBaseline />
          <HorizonProvider>
            <RouterProvider router={router} />
          </HorizonProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </StrictMode>
  );
};

export default App;
