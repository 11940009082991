import { Button, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

export interface LinkButtonProps {
  path: string;
  label: string;
  active: boolean;
}
const LinkButton = ({ path, label, active }: LinkButtonProps) => {
  const theme = useTheme();
  return (
    <Link to={path} key={`${label}-link-to`}>
      <Button
        variant="contained"
        size="small"
        sx={{
          boxShadow: "none",
          backgroundColor: active ? theme.palette.primary.dark : "none",
          "&:hover": {
            boxShadow: "none"
          },
          "&:active": {
            boxShadow: "none"
          }
        }}
      >
        {label}
      </Button>
    </Link>
  );
};
export default LinkButton;
