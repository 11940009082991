export const getApiURL = (): string => {
  return `${
    process.env.REACT_APP_API_URL ??
    new URL(`${window.location.protocol}//${window.location.host}`).toString()
  }`;
};

export const getApiDefaultHeaders = (
  apiToken: string
): { [header: string]: string } => {
  return {
    Authorization: `Bearer ${apiToken}`
  };
};
