const DOMAIN_PRODUCTION = "pro.future-tools.clients.dive.tech";

const defaults = {
  KEYCLOAK_URL: "https://auth.dev.future-tools.clients.dive.tech",
  KEYCLOAK_REALM: "dive",
  KEYCLOAK_CLIENT_ID: "horizon"
};

const variables = {
  development: {
    ...defaults,
    KEYCLOAK_URL:
      (process.env.REACT_APP_KEYCLOAK_URL as string) || defaults.KEYCLOAK_URL,
    KEYCLOAK_REALM:
      (process.env.REACT_APP_KEYCLOAK_REALM as string) ||
      defaults.KEYCLOAK_REALM,
    KEYCLOAK_CLIENT_ID:
      (process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string) ||
      defaults.KEYCLOAK_CLIENT_ID
  },
  production: {
    KEYCLOAK_URL: "https://pro.auth.dev.future-tools.clients.dive.tech",
    KEYCLOAK_REALM: "dive",
    KEYCLOAK_CLIENT_ID: "horizon"
  }
};

const getVariables = () => {
  if (window.location.host === DOMAIN_PRODUCTION) {
    return variables.production;
  }

  return variables.development;
};

export const { KEYCLOAK_URL, KEYCLOAK_REALM, KEYCLOAK_CLIENT_ID } =
  getVariables();
