import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import {
  ChangeEvent,
  useMemo,
  useState,
  MouseEvent,
  SetStateAction,
  Dispatch
} from "react";
import CustomTableHead from "./TableHead";
import CustomTableRow from "./TableRow";
import { CustomTableData, Order, TableHeadCell } from "./types";
import { getComparator, stableSort } from "./utils";

export interface CustomTableProps {
  columns: TableHeadCell[];
  rows: CustomTableData[];
  pageSizeOpts?: number[];
  defaultRowsPerPage?: number;
  formatFn: (value: number) => string;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
}

const CustomTable = ({
  columns,
  rows,
  pageSizeOpts,
  defaultRowsPerPage,
  formatFn,
  page,
  setPage
}: CustomTableProps) => {
  const theme = useTheme();
  const pageSizeOptions = pageSizeOpts ?? [5, 10, 20];
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof CustomTableData>("id");
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage ?? 5);

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof CustomTableData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch"
      }}
    >
      <TableContainer sx={{ height: "100%" }}>
        <Table aria-labelledby="tableTitle" size={"medium"}>
          <CustomTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headers={columns}
          />
          <TableBody>
            {visibleRows.map((row) => {
              return (
                <CustomTableRow tabIndex={-1} key={row.id}>
                  <TableCell align="center" component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell align="center">{row.label}</TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      backgroundColor: row.value < 0 ? "#FEF3F2" : "#ECFDF3",
                      color:
                        row.value < 0
                          ? theme.palette.customColors.negativeVariationColor
                          : theme.palette.customColors.positiveVariationColor,
                      fontWeight: "bold",
                      fontSize: 14
                    }}
                  >
                    {formatFn(row.value)}
                  </TableCell>
                </CustomTableRow>
              );
            })}
            {emptyRows > 0 && (
              <CustomTableRow
                style={{
                  height: 53 * emptyRows
                }}
              >
                <TableCell colSpan={6} />
              </CustomTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          height: "5rem"
        }}
        size="small"
        rowsPerPageOptions={pageSizeOptions}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        showFirstButton={true}
        showLastButton={true}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={<p></p>}
        slotProps={{ select: { sx: { display: "none" } } }}
      />
    </Box>
  );
};

export default CustomTable;
