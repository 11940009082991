import { NO_BRAND_ID } from "@constants/horizon";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "@tanstack/react-query";
import { getApiDefaultHeaders, getApiURL } from "../Base";
import { APIException } from "../model/APIException";
import { APIResponse } from "../model/APIResponse";
import { APIError } from "../model/horizon";
import { FunnelStateMetrics } from "../model/horizon/FunnelStateMetrics";

export interface FunnelStateMetricsParams {
  sectorId: string;
  brandId: string;
}

export const useFunnelStateMetrics = ({
  sectorId,
  brandId
}: FunnelStateMetricsParams) => {
  const { keycloak } = useKeycloak();

  return useQuery({
    queryKey: ["funnel_state", "metrics", sectorId, brandId],
    enabled:
      keycloak.authenticated &&
      (keycloak.token ?? "").length > 0 &&
      brandId.length > 0 &&
      brandId !== NO_BRAND_ID &&
      sectorId.length > 0,
    queryFn: () =>
      fetch(
        `${getApiURL()}horizon-back/v1/metrics/funnel_state?&brand_id=${brandId}&sector_id=${sectorId}`,
        {
          headers: { ...getApiDefaultHeaders(keycloak.token ?? "") }
        }
      )
        .then((res) => {
          const response: APIResponse = {
            status: res.status,
            body: res.json()
          };
          return response;
        })
        .then((res) => {
          if (res.status === 200) {
            return res.body as FunnelStateMetrics[];
          }
          const apiError = res.body as APIError;
          throw new APIException(apiError.detail, res.status);
        })
  });
};

export const useFunnelStateMetricsExcel = ({
  sectorId,
  brandId
}: FunnelStateMetricsParams) => {
  const { keycloak } = useKeycloak();

  return useQuery({
    queryKey: ["funnel_state", "metrics", "excel", sectorId, brandId],
    enabled: false,
    queryFn: () =>
      fetch(
        `${getApiURL()}horizon-back/v1/metrics/funnel_state/excel?&brand_id=${brandId}&sector_id=${sectorId}`,
        {
          headers: { ...getApiDefaultHeaders(keycloak.token ?? "") }
        }
      )
        .then((res) => {
          const response: APIResponse = {
            status: res.status,
            body: res.blob()
          };
          return response;
        })
        .then((res) => {
          if (res.status === 200) {
            return res.body as Blob;
          }
          const apiError = res.body as APIError;
          throw new APIException(apiError.detail, res.status);
        })
  });
};
