export const CHART_COLORS_PAST = [
  "#2746f4",
  "#85e139",
  "#5fe9d0",
  "#36bffa",
  "#6172f3",
  "#d444f1",
  //test colors below
  "#1b4f72",
  "#154360",
  "#4a235a",
  "#512e5f",
  "#78281f",
  "#641e16"
];
export const CHART_COLORS_FUTURE = [
  "#a4b1f2",
  "#c7dcb6",
  "#c9e7e1",
  "#b1e6fd",
  "#a8b0f1",
  "#dd83ef",
  //test colors below
  "#aed6f1",
  "#a9cce3",
  "#d2b4de",
  "#d7bde2",
  "#f5b7b1",
  "#e6b0aa"
];
