/** @jsxImportSource @emotion/react */
import { NO_BRAND_ID } from "@constants/horizon";
import { HorizonContext } from "@context";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Paper from "@mui/material/Paper";
import { useSectors, useYougovKPIs } from "@services/api/horizon";
import { useBrandEquityMetricsExcel } from "@services/api/horizon/BrandEquityMetrics";
import { getExcelDateFmt } from "@utils/ExcelUtils";
import BrandSelector from "@views/ViewHorizon/components/Selectors/BrandSelector";
import IndustrySelector from "@views/ViewHorizon/components/Selectors/IndustrySelector";
import KpiSelector from "@views/ViewHorizon/components/Selectors/KpiSelector";
import MarketBrandsSelector from "@views/ViewHorizon/components/Selectors/MarketBrandsSelector";
import saveAs from "file-saver";
import { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const BrandEquitySelectors = () => {
  const { t } = useTranslation();
  const { currentCtx, saveCurrentCtx } = useContext(HorizonContext);
  const [clicked, setClicked] = useState(false);

  const { data: sectors } = useSectors();
  const { data: kpis } = useYougovKPIs();

  const sectorNameById = useMemo(() => {
    const sectorsById: { [key: string]: string } = {};
    (sectors ?? []).forEach((sector) => {
      sectorsById[sector.id.toString()] = sector.label;
    });
    return sectorsById;
  }, [sectors]);

  const kpiNameById = useMemo(() => {
    const kpisById: { [key: string]: string } = {};
    (kpis ?? []).forEach((kpi) => {
      kpisById[kpi.id.toString()] = kpi.label;
    });
    return kpisById;
  }, [kpis]);

  const handleComputeTrendChange = (event: ChangeEvent<HTMLInputElement>) => {
    saveCurrentCtx({
      ...currentCtx,
      brandEquityCtx: {
        ...currentCtx.brandEquityCtx,
        withTrend: event.target.checked
      }
    });
  };

  const handleGenerateInsightsChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    saveCurrentCtx({
      ...currentCtx,
      brandEquityCtx: {
        ...currentCtx.brandEquityCtx,
        withPrediction: event.target.checked
      }
    });
  };

  const {
    data: excelBlob,
    refetch: refetchExcel,
    isFetching: isFetchingExcel
  } = useBrandEquityMetricsExcel({
    kpiId: currentCtx.kpiId,
    sectorId: currentCtx.sectorId
  });

  const excelFileName = useMemo(() => {
    const sectorName =
      sectorNameById[currentCtx.sectorId] ?? currentCtx.sectorId.toString();

    const kpiName =
      kpiNameById[currentCtx.kpiId] ?? currentCtx.kpiId.toString();
    return `Report_Brand_Equity_${sectorName}_${kpiName}_${getExcelDateFmt()}.xlsx`;
  }, [currentCtx.sectorId, currentCtx.kpiId, kpiNameById, sectorNameById]);

  useEffect(() => {
    if (excelBlob && clicked) {
      saveAs(excelBlob, excelFileName);
      setClicked(false);
    }
  }, [excelBlob, excelFileName, clicked]);

  const handleDownloadReport = () => {
    setClicked(true);
    refetchExcel();
  };

  const isEnabledDownloadForecastButton: boolean = useMemo(() => {
    return currentCtx.sectorId.length > 0 && currentCtx.kpiId.length > 0;
  }, [currentCtx]);

  const isEnabledBrandForecastingButtons: boolean = useMemo(() => {
    return (
      currentCtx.sectorId.length > 0 &&
      currentCtx.kpiId.length > 0 &&
      currentCtx.brandId.length > 0
    );
  }, [currentCtx]);

  const customOpts = [
    { id: NO_BRAND_ID, label: t("horizon.brand_equity.industry_avg_selector") }
  ];

  return (
    <Box sx={{ height: "100%" }}>
      <Paper
        sx={{
          height: "100%",
          display: "flex",
          gap: 2,
          flexDirection: "column",
          justifyContent: "space-between",
          padding: 2,
          paddingBottom: 4
        }}
        elevation={0}
      >
        <Box>
          <Typography variant="h5" gutterBottom>
            Horizon
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <IndustrySelector />
            <KpiSelector />
            <BrandSelector customOpts={customOpts} />
            <MarketBrandsSelector />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              marginTop: 2
            }}
          >
            <FormGroup>
              <FormControlLabel
                sx={{ height: "2rem" }}
                control={
                  <Checkbox
                    checked={currentCtx.brandEquityCtx.withTrend}
                    disabled={!isEnabledBrandForecastingButtons}
                    onChange={handleComputeTrendChange}
                  />
                }
                label={t("horizon.brand_equity.compute_trend")}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                sx={{ height: "2rem" }}
                control={
                  <Checkbox
                    checked={currentCtx.brandEquityCtx.withPrediction}
                    disabled={!isEnabledBrandForecastingButtons}
                    onChange={handleGenerateInsightsChange}
                  />
                }
                label={t("horizon.brand_equity.generate_insights")}
              />
            </FormGroup>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Button
            variant="contained"
            disabled={!isEnabledDownloadForecastButton}
            onClick={handleDownloadReport}
            sx={{
              textTransform: "none"
            }}
          >
            {isFetchingExcel && (
              <CircularProgress size="1.5rem" sx={{ color: "white" }} />
            )}
            {!isFetchingExcel && t("horizon.brand_equity.download_forecast")}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default BrandEquitySelectors;
