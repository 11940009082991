import { HorizonContext } from "@context";
import LineChart, {
  CartesianMarkerProps,
  DatumValue,
  LineChartData
} from "@dive.tv/dss-ui-graphs/LineChart";
import { Box, useMediaQuery, useTheme } from "@mui/material";

import LineChartTooltip from "@views/ViewHorizon/components/Charts/LineTooltip";
import { TFunction } from "i18next";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BrandEquityLineChartData } from "./types";
import { toLineChartData } from "./utils";

interface MultiLineChartCardProps {
  data: BrandEquityLineChartData[];
  lastDateWithData: Date;
}

const MultiLineChartCard = ({
  data,
  lastDateWithData
}: MultiLineChartCardProps) => {
  const { currentCtx } = useContext(HorizonContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const chartData: LineChartData[] = useMemo(
    () => toLineChartData(data, t),
    [data, t]
  );

  const predictionMonthsMarks = [24, 12, 6];
  const generatePredictedDate = (
    currentDate: Date,
    month: number,
    t: TFunction<"translation", undefined>
  ): CartesianMarkerProps<DatumValue> => {
    const predictedDate = new Date(currentDate); // clonamos la fecha actual
    predictedDate.setMonth(predictedDate.getMonth() + month); // sumamos los meses

    // formateamos la fecha
    const formatedPredictedDate = predictedDate.toLocaleDateString("en-ES", {
      month: "short",
      year: "numeric"
    });

    return {
      axis: "x",
      legend: `${month} ${t("common.time.months")}`,
      legendPosition: "top",
      lineStyle: {
        stroke: "#000000",
        strokeWidth: 2,
        strokeDasharray: "3, 3"
      },
      textStyle: {
        fontSize: 12
      },
      value: formatedPredictedDate
    };
  };

  const predictedDates: CartesianMarkerProps<DatumValue>[] =
    predictionMonthsMarks.map((month) =>
      generatePredictedDate(lastDateWithData, month, t)
    );

  const predictionMarkers: CartesianMarkerProps<DatumValue>[] | undefined =
    currentCtx.brandEquityCtx.withPrediction ? predictedDates : undefined;

  return (
    <Box
      sx={{
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        backgroundColor: "#ffffff",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        height: "100%",
        padding: "15px",
        position: "relative"
      }}
    >
      <Box
        sx={{
          overflowX: isTablet ? "scroll" : "none", // Estilos para "quitar" la barra de scroll
          scrollbarWidth: "none", // Estilos para "quitar" la barra de scroll - Firefox
          "&::-webkit-scrollbar": {
            display: "none" // Estilos para "quitar" la barra de scroll - Chrome, Safari y Edge
          },
          height: "inherit",
          marginBottom: 1
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: isTablet ? "1276px" : "100%"
          }}
        >
          <LineChart
            data={chartData}
            responsiveLineProps={{
              animate: true,
              crosshairType: "bottom-left",
              curve: "linear",
              tooltip: LineChartTooltip,
              axisLeft: {
                tickSize: 5,
                tickPadding: 10
              },
              axisBottom: {
                tickRotation: 45,
                tickPadding: 10
              },
              markers: predictionMarkers,
              margin: { top: 50, left: 40, right: 150, bottom: 80 },
              legends: [
                {
                  anchor: "right",
                  direction: "column",
                  itemHeight: 10,
                  itemWidth: 150,
                  itemDirection: "left-to-right",
                  symbolSize: 15,
                  itemsSpacing: 20,
                  toggleSerie: true,
                  translateX: 170,
                  translateY: 0,
                  itemOpacity: 1,
                  symbolShape: (s) => (
                    <line
                      x1={s.x}
                      y1={s.y + 7} // Coloca la línea a distancia del y inicial para centrarla verticalmente
                      x2={s.x + 15} // Largo de la línea
                      y2={s.y + 7} // Mantener y1 e y2 iguales para que sea horizontal
                      stroke={s.fill} // El color de la línea es el mismo que el 'fill'
                      strokeWidth={4} // Grosor de la línea
                      strokeLinecap="round" // Hace los extremos de la línea redondeados
                    />
                  )
                }
              ]
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MultiLineChartCard;
