/** @jsxImportSource @emotion/react */
import { HorizonContext } from "@context";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useSectors } from "@services/api/horizon";
import { useIndustryTrendMetricsExcel } from "@services/api/horizon/IndustryTrendMetrics";
import { Sector } from "@services/api/model/horizon";
import { getExcelDateFmt } from "@utils/ExcelUtils";
import IndustrySelector from "@views/ViewHorizon/components/Selectors/IndustrySelector";
import KpiSelector from "@views/ViewHorizon/components/Selectors/KpiSelector";
import MultiBrandSelector from "@views/ViewHorizon/components/Selectors/MultiBrandSelector";
import TimePeriodSelector from "@views/ViewHorizon/components/Selectors/TimePeriodSelector";
import saveAs from "file-saver";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const IndustryTrendSelectors = () => {
  const { t } = useTranslation();
  const { currentCtx } = useContext(HorizonContext);
  const [clicked, setClicked] = useState(false);

  const { data: sectors } = useSectors();

  const brandsBySector: { [key: string]: string[] } = useMemo(() => {
    const brandsBySector: Record<string, string[]> = {};
    (sectors ?? []).forEach((sector: Sector) => {
      brandsBySector[sector.id.toString()] = sector.brands.map((brand) =>
        brand.id.toString()
      );
    });
    return brandsBySector;
  }, [sectors]);

  const {
    data: excelBlob,
    refetch: refetchExcel,
    isFetching: isFetchingExcel
  } = useIndustryTrendMetricsExcel({
    sectorId: currentCtx.sectorId,
    kpiId: currentCtx.kpiId,
    brandIds:
      currentCtx.industryTrendCtx.brandIds.length > 0
        ? currentCtx.industryTrendCtx.brandIds
        : (brandsBySector[currentCtx.sectorId] ?? []),
    timePeriod: currentCtx.industryTrendCtx.timePeriod
  });

  const isEnabledDownloadReportButton: boolean = useMemo(() => {
    return (
      currentCtx.sectorId.length > 0 &&
      currentCtx.kpiId.length > 0 &&
      currentCtx.industryTrendCtx.timePeriod.length > 0 &&
      (currentCtx.industryTrendCtx.brandIds.length > 0 ||
        (brandsBySector[currentCtx.sectorId] ?? []).length > 0)
    );
  }, [currentCtx, brandsBySector]);

  useEffect(() => {
    if (excelBlob && clicked) {
      saveAs(excelBlob, `Report_Industry_Trend_${getExcelDateFmt()}.xlsx`);
      setClicked(false);
    }
  }, [excelBlob, clicked]);

  const handleDownloadReport = () => {
    setClicked(true);
    refetchExcel();
  };

  return (
    <Box sx={{ height: "100%" }}>
      <Paper
        sx={{
          height: "100%",
          display: "flex",
          gap: 2,
          flexDirection: "column",
          justifyContent: "space-between",
          padding: 2,
          paddingBottom: 4
        }}
        elevation={0}
      >
        <Box>
          <Typography variant="h5" gutterBottom>
            Horizon
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <IndustrySelector />
            <KpiSelector />
            <MultiBrandSelector />
            <TimePeriodSelector />
          </Box>
        </Box>
        <Button
          variant="contained"
          onClick={handleDownloadReport}
          disabled={!isEnabledDownloadReportButton}
          sx={{ textTransform: "none" }}
        >
          {isFetchingExcel && (
            <CircularProgress size="1.5rem" sx={{ color: "white" }} />
          )}
          {!isFetchingExcel && t("horizon.industry_trend.download_report")}
        </Button>
      </Paper>
    </Box>
  );
};

export default IndustryTrendSelectors;
