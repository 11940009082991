import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import { Chip, IconButton, Stack, Tooltip } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useMemo, useState } from "react";

export interface MultiSelectOption {
  id: string;
  label: string;
}

export interface MultiSelectProps {
  id: string;
  variant: "inner-label" | "outer-label";
  inputLabel: string;
  value: string[];
  onChange: (event: any) => void;
  onDelete: (value: string) => void;
  onClose: (event: any) => void;
  options: MultiSelectOption[];
  placeHolder: string;
}

const CustomMultiSelect = ({
  id,
  variant,
  inputLabel,
  value,
  onChange,
  onDelete,
  onClose,
  options,
  placeHolder
}: MultiSelectProps) => {
  const [itemsById, setItemsById] = useState<Record<string, string>>({});

  const items = useMemo(() => {
    const itemsByIdTmp: Record<string, string> = {};
    const optionItems: JSX.Element[] = options.map((opt) => {
      itemsByIdTmp[opt.id] = opt.label;
      return (
        <MenuItem value={opt.id} key={`${id}-option-${opt.id}`}>
          {opt.label}
        </MenuItem>
      );
    });
    setItemsById(itemsByIdTmp);
    return optionItems;
  }, [id, options]);

  if (variant === "inner-label") {
    return (
      <FormControl fullWidth>
        <InputLabel id={`${id}-helper-label`}>{inputLabel}</InputLabel>
        <Tooltip title="Delete" placement="right-start">
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
        <Select
          multiple
          labelId={`${id}-helper-label`}
          id={`${id}-helper`}
          value={value}
          onChange={onChange}
          onClose={onClose}
          renderValue={(selected) => (
            <Stack gap={1} direction="row" flexWrap="wrap">
              {selected.length === 0 && <em>{placeHolder}</em>}
              {selected.length > 0 &&
                selected.map((value) => (
                  <Chip
                    key={value}
                    label={itemsById[value] ?? value}
                    onDelete={() => onDelete(value)}
                    deleteIcon={
                      <CancelIcon
                        onMouseDown={(event) => event.stopPropagation()}
                      />
                    }
                  />
                ))}
            </Stack>
          )}
        >
          <MenuItem disabled value="">
            <em>{placeHolder}</em>
          </MenuItem>
          {items}
        </Select>
      </FormControl>
    );
  }

  return (
    <React.Fragment>
      <InputLabel id={`${id}-helper-label`}>{inputLabel}</InputLabel>

      <FormControl fullWidth>
        <Select
          multiple
          id={`${id}-helper`}
          value={value}
          onChange={onChange}
          displayEmpty
          renderValue={(selected) => (
            <Stack
              gap={1}
              direction="row"
              flexWrap="wrap"
              sx={{ maxHeight: "150px", overflowY: "auto" }}
            >
              {selected.length === 0 && <em>{placeHolder}</em>}
              {selected.length > 0 &&
                selected.map((value) => (
                  <Chip
                    key={value}
                    label={itemsById[value] ?? value}
                    onDelete={() => onDelete(value)}
                    deleteIcon={
                      <CancelIcon
                        onMouseDown={(event) => event.stopPropagation()}
                      />
                    }
                  />
                ))}
            </Stack>
          )}
        >
          <MenuItem disabled value="">
            <em>{placeHolder}</em>
          </MenuItem>
          {items}
        </Select>
      </FormControl>
    </React.Fragment>
  );
};

export default CustomMultiSelect;
