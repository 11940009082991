import { createTheme } from "@mui/material/styles";

// Layout heights root -> navbar / content
const rootHeight = "100vh";
const appNavBarHeight = "4rem";

// Extend module '@mui/material/styles' to add new properties
declare module "@mui/material/styles" {
  interface Theme {
    customTransitions: {
      colorTransition: string;
    };
    customLayoutHeight: {
      rootHeight: string;
      appNavBarHeight: string;
    };
  }
  interface Palette {
    customColors: {
      positiveVariationColor: string;
      negativeVariationColor: string;
    };
  }
  // to work in sync with `createTheme`
  interface ThemeOptions {
    customTransitions?: {
      colorTransition?: string;
    };
    customLayoutHeight?: {
      rootHeight?: string;
      appNavBarHeight?: string;
    };
  }
  interface PaletteOptions {
    customColors?: {
      positiveVariationColor?: string;
      negativeVariationColor?: string;
    };
  }
}

const NewFutureToolsTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#2645F5"
    },
    secondary: {
      main: "#fa6375",
      contrastText: "#ffffff"
    },
    background: {
      default: "#f7f7f7"
    },
    error: {
      main: "#ff0004"
    },
    customColors: {
      positiveVariationColor: "#027A48",
      negativeVariationColor: "#B42318"
    }
  },
  shape: {
    borderRadius: 5
  },
  customTransitions: {
    colorTransition: "all 150ms ease-in-out"
  },
  customLayoutHeight: {
    rootHeight: rootHeight,
    appNavBarHeight: appNavBarHeight
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none"
        }
      }
    }
  }
});

export default NewFutureToolsTheme;
