import { LineChartData } from "@dive.tv/dss-ui-graphs/LineChart";
import { TFunction } from "i18next";
import { BrandEquityLineChartData } from "./types";

export const toLineChartData = (
  data: BrandEquityLineChartData[],
  t: TFunction<"translation", undefined>
): LineChartData[] => {
  const lineChartData: LineChartData[] = [];

  data.forEach((brandData) => {
    lineChartData.push({
      id: brandData.id,
      color: brandData.color,
      data: [...brandData.pastData]
    });

    if (brandData.predictedData.length > 0) {
      lineChartData.push({
        id: `${brandData.id} ${t("horizon.brand_equity.forecast")}`,
        color: brandData.colorPredicted,
        data: [...brandData.predictedData]
      });
    }
  });

  return lineChartData;
};
