import MultiSelect, { MultiSelectOption } from "@components/Select/MultiSelect";
import { HorizonContext } from "@context";
import { useSectors } from "@services/api/horizon";
import { Brand } from "@services/api/model/horizon";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

const MultiBrandSelector = () => {
  const { t } = useTranslation();
  const { currentCtx, saveCurrentCtx } = useContext(HorizonContext);

  const { data } = useSectors();

  const handleBrandChange = (event: any) => {
    saveCurrentCtx({
      ...currentCtx,
      industryTrendCtx: {
        ...currentCtx.industryTrendCtx,
        brandIds: event.target.value
      }
    });
  };
  const handleBrandDelete = (deletedBrandId: string) => {
    const brandsFiltered = currentCtx.industryTrendCtx.brandIds.filter(
      (brand_id) => brand_id !== deletedBrandId
    );

    saveCurrentCtx({
      ...currentCtx,
      industryTrendCtx: {
        ...currentCtx.industryTrendCtx,
        brandIds: brandsFiltered
      }
    });
  };

  const brandsBySector: { [key: string]: Brand[] } = useMemo(() => {
    const brandsBySector: Record<string, Brand[]> = {};
    (data ?? []).map((sector) => {
      brandsBySector[sector.id] = sector.brands;
      return { ...sector, id: sector.id.toString() };
    });
    return brandsBySector;
  }, [data]);

  const brandOptions: MultiSelectOption[] = useMemo(() => {
    return (brandsBySector[currentCtx.sectorId] ?? []).map((brand) => ({
      ...brand,
      id: brand.id.toString()
    }));
  }, [brandsBySector, currentCtx.sectorId]);

  return (
    <MultiSelect
      id="brand-select"
      variant="outer-label"
      inputLabel={t("horizon.selectors.brand")}
      value={currentCtx.industryTrendCtx.brandIds}
      onChange={handleBrandChange}
      onDelete={handleBrandDelete}
      onClose={() => {}}
      options={brandOptions}
      placeHolder={t("horizon.selectors.brands_placeholder")}
    />
  );
};
export default MultiBrandSelector;
