import { IndustryPageTime } from "@constants/time";

export interface IIndustryTrendContext {
  brandIds: string[];
  timePeriod: IndustryPageTime;
}

export interface IBrandEquityContext {
  marketBrandIds: string[];
  withTrend: boolean;
  withPrediction: boolean;
}

export interface IHorizonContext {
  sectorId: string; // Common to all pages
  kpiId: string; // Common to almost all pages
  brandId: string; // Common to almost all pages
  industryTrendCtx: IIndustryTrendContext;
  brandEquityCtx: IBrandEquityContext;
}

export type HorizonContextType = {
  currentCtx: IHorizonContext;
  saveCurrentCtx: (sectorContext: IHorizonContext) => void;
};

export const DefaultHorizonContextType: HorizonContextType = {
  currentCtx: {
    sectorId: "",
    kpiId: "",
    brandId: "",
    industryTrendCtx: {
      brandIds: [],
      timePeriod: IndustryPageTime.QTD
    },
    brandEquityCtx: {
      marketBrandIds: [],
      withTrend: false,
      withPrediction: false
    }
  },
  saveCurrentCtx: (_: IHorizonContext) => {}
};
