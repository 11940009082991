import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
  Typography
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { MouseEvent } from "react";
import { CustomTableData, Order, TableHeadCell } from "./types";

interface CustomTableHeadProps {
  onRequestSort: (
    event: MouseEvent<unknown>,
    property: keyof CustomTableData
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headers: TableHeadCell[];
}

const CustomTableHead = (props: CustomTableHeadProps) => {
  const { order, orderBy, onRequestSort, headers } = props;
  const createSortHandler =
    (property: keyof CustomTableData) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headers.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={"normal"}
            size="small"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography variant="body2" fontSize="small">
                {headCell.label}
              </Typography>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHead;
