import LayoutHorizon from "@layouts/LayoutHorizon";
import Charts from "./components/Charts";
import Selectors from "./components/Selectors";

const BrandEquityForecastView = () => {
  return (
    <LayoutHorizon selectors={<Selectors />}>
      <Charts />
    </LayoutHorizon>
  );
};

export default BrandEquityForecastView;
