import { IndustryPageTime } from "@constants/time";
import React, { createContext, useState } from "react";
import {
  HorizonContextType,
  DefaultHorizonContextType,
  IHorizonContext
} from "./HorizonContextTypes";

export const HorizonContext = createContext<HorizonContextType>(
  DefaultHorizonContextType
);

const HorizonProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [currentCtx, setCurrentCtx] = useState<IHorizonContext>({
    sectorId: "",
    kpiId: "",
    brandId: "",
    industryTrendCtx: {
      brandIds: [],
      timePeriod: IndustryPageTime.QTD
    },
    brandEquityCtx: {
      marketBrandIds: [],
      withTrend: false,
      withPrediction: false
    }
  });

  const saveCurrentCtx = (ctx: IHorizonContext) => {
    setCurrentCtx({ ...ctx });
  };

  return (
    <HorizonContext.Provider value={{ currentCtx, saveCurrentCtx }}>
      {children}
    </HorizonContext.Provider>
  );
};

export default HorizonProvider;
