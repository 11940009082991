import Select, {
  SingleSelectOption
} from "@components/Select/SingleSelect/Select";
import { IndustryPageTime } from "@constants/time";
import { HorizonContext } from "@context";
import { SelectChangeEvent } from "@mui/material";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

const TimePeriodSelector = () => {
  const { t } = useTranslation();
  const { currentCtx, saveCurrentCtx } = useContext(HorizonContext);

  const handleTimePeriodChange = (event: SelectChangeEvent) => {
    saveCurrentCtx({
      ...currentCtx,
      industryTrendCtx: {
        ...currentCtx.industryTrendCtx,
        timePeriod: event.target.value as IndustryPageTime
      }
    });
  };

  const timePeriods: SingleSelectOption[] = useMemo(() => {
    return Object.keys(IndustryPageTime).map((timeOpt) => ({
      id: timeOpt,
      label: timeOpt
    }));
  }, []);

  return (
    <Select
      id="time-period-select"
      variant="outer-label"
      inputLabel={t("horizon.selectors.time_period")}
      value={currentCtx.industryTrendCtx.timePeriod}
      onChange={handleTimePeriodChange}
      options={timePeriods}
    />
  );
};
export default TimePeriodSelector;
