import { MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { useMemo } from "react";

export interface SingleSelectOption {
  id: string;
  label: string;
}

export interface SelectProps {
  id: string;
  variant: "inner-label" | "outer-label";
  inputLabel: string;
  value: string;
  onChange: (event: SelectChangeEvent) => void;
  options: SingleSelectOption[];
}

const CustomSelect = ({
  id,
  variant,
  inputLabel,
  value,
  onChange,
  options
}: SelectProps) => {
  const items = useMemo(() => {
    return options.map((opt) => (
      <MenuItem value={opt.id} key={`${id}-${opt.id}`}>
        {opt.label}
      </MenuItem>
    ));
  }, [id, options]);

  if (variant === "inner-label") {
    return (
      <FormControl fullWidth>
        <InputLabel id={`${id}-helper-label`}>{inputLabel}</InputLabel>
        <Select
          labelId={`${id}-helper-label`}
          id={`${id}-helper`}
          value={value}
          onChange={onChange}
        >
          {items}
        </Select>
      </FormControl>
    );
  }

  return (
    <React.Fragment>
      <InputLabel id={`${id}-helper-label`}>{inputLabel}</InputLabel>
      <FormControl fullWidth>
        <Select
          id={`${id}-helper`}
          value={value}
          onChange={onChange}
          displayEmpty
        >
          {items}
        </Select>
      </FormControl>
    </React.Fragment>
  );
};

export default CustomSelect;
