import Select, {
  SingleSelectOption
} from "@components/Select/SingleSelect/Select";
import { HorizonContext } from "@context";
import { useSectors } from "@services/api/horizon";
import { Brand } from "@services/api/model/horizon";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface BrandSelectorProps {
  customOpts: SingleSelectOption[];
}

const BrandSelector = ({ customOpts }: BrandSelectorProps) => {
  const { t } = useTranslation();

  const { currentCtx, saveCurrentCtx } = useContext(HorizonContext);

  const { data } = useSectors();

  const handleBrandChange = (event: any) => {
    const value = event.target.value;
    saveCurrentCtx({
      ...currentCtx,
      brandId: value,
      brandEquityCtx: {
        ...currentCtx.brandEquityCtx,
        marketBrandIds: []
      }
    });
  };

  const brandsBySector: { [key: string]: Brand[] } = useMemo(() => {
    const brandsBySector: Record<string, Brand[]> = {};
    (data ?? []).map((sector) => {
      brandsBySector[sector.id] = sector.brands;
      return { ...sector, id: sector.id.toString() };
    });
    return brandsBySector;
  }, [data]);

  const brandOptions: SingleSelectOption[] = useMemo(() => {
    const brandOptionsTmp = (brandsBySector[currentCtx.sectorId] ?? []).map(
      (brand) => ({
        ...brand,
        id: brand.id.toString()
      })
    );

    if (brandOptionsTmp.length > 0) {
      customOpts.forEach((customOpt) => {
        brandOptionsTmp.unshift({ ...customOpt });
      });
    }

    return brandOptionsTmp;
  }, [brandsBySector, customOpts, currentCtx.sectorId]);

  return (
    <Select
      id="brand-select"
      variant="outer-label"
      inputLabel={t("horizon.selectors.brand")}
      value={currentCtx.brandId}
      onChange={handleBrandChange}
      options={brandOptions}
    ></Select>
  );
};

export default BrandSelector;
