import futureToolsByISPDLogo from "@assets/images/icon-future-tools.svg";
import horizonIcon from "@assets/images/illustration-futurebrands.svg";
// import lightHouseIcon from "@assets/images/illustration-lighthouse.svg";
// import wavesIcon from "@assets/images/illustration-mediamix.svg";
// import compassIcon from "@assets/images/illustration-spectrum.svg";
import SimulatorCard from "@components/SimulatorCard/SimulatorCard";
import Routes from "@constants/routing";
import { Box, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";

const ViewHome = () => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();

  return (
    <div className="w-screen md:h-screen relative flex flex-col items-center justify-center p-4">
      <div className="md:absolute md:top-3 md:right-6 w-full flex justify-end">
        {keycloak.authenticated ? (
          <Button
            variant="contained"
            size="small"
            onClick={() => keycloak.logout()}
          >
            Log out
          </Button>
        ) : null}
      </div>
      <Box className="md:w-4/4 md:h-screen flex flex-col gap-4 md:gap-10 justify-center">
        <div className="flex flex-col gap-4 w-full mt-4">
          <img
            className="w-full md:w-[346px] max-w-full h-auto block"
            src={futureToolsByISPDLogo}
            alt="Future Tools By ISPD Logo"
          />
          <Typography variant="body1">{t("hub_page.subtitle")}</Typography>
        </div>
        <Grid container columnSpacing={3} rowSpacing={3}>
          {/* <Grid item xs={12} md={6}>
            <SimulatorCard
              icon={lightHouseIcon}
              to={Routes.Lighthouse}
              title="Lighthouse"
              description={t("hub_page.lighthouse")}
            />
          </Grid> */}
          <Grid item xs={12} md={12}>
            <SimulatorCard
              icon={horizonIcon}
              to={Routes.Horizon}
              title="Horizon"
              alignItems="center"
              description={t("hub_page.horizon")}
            />
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <SimulatorCard
              icon={wavesIcon}
              to={Routes.Waves}
              title="Waves"
              description={t("hub_page.waves")}
            />
          </Grid> */}
          {/* <Grid item xs={12} md={6}>
            <SimulatorCard
              icon={compassIcon}
              to={Routes.Compass}
              title="Compass"
              description={t("hub_page.compass")}
            />
          </Grid> */}
        </Grid>
      </Box>
    </div>
  );
};

export default ViewHome;
