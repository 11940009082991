import { FunnelStateMetrics } from "@services/api/model/horizon/FunnelStateMetrics";

export function validateData(data: FunnelStateMetrics): boolean {
  return data.groups.every((group) =>
    group.data
      .slice(1)
      .every(
        (item) => item.ratio !== null && item.value !== null && item.value !== 0
      )
  );
}
