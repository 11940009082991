import {
  BrandEquityForecastMetrics,
  BrandEquityForecastMetricsData
} from "@services/api/model/horizon/BrandEquityMetrics";
import { BrandEquityLineChartData } from "../MultiLineChartCard/types";

export const toLineChartPoint = (
  data: BrandEquityForecastMetricsData
): { x: string | Date; y: number } => {
  const datasetDate = new Date(`${data.year}-${data.month}-01`);

  // Format the date for the x-axis
  const formattedDate = datasetDate.toLocaleDateString("en-ES", {
    month: "short",
    year: "numeric"
  });

  return {
    x: formattedDate,
    y: data.value
  };
};

export const processBrandEquityLineChartData = (
  brandMetric: BrandEquityForecastMetrics,
  chartData: BrandEquityLineChartData[],
  id: string,
  color: string,
  colorPredicted: string
): Date => {
  const pastData: { x: string | Date; y: number }[] = [];
  const predictedData: { x: string | Date; y: number }[] = [];
  const pastDates: Date[] = [];

  let isFirstPredicted = true;

  brandMetric.data.forEach((data, index) => {
    const point = toLineChartPoint(data);
    if (data.predicted) {
      if (isFirstPredicted) {
        const lastPastData = brandMetric.data[index - 1];
        const lastPastDataPoint = toLineChartPoint(lastPastData);

        predictedData.push(lastPastDataPoint);

        isFirstPredicted = false;
      }

      predictedData.push(point);
    } else {
      pastDates.push(new Date(`${data.year}-${data.month}-01`));
      pastData.push(point);
    }
  });

  chartData.push({
    id,
    color,
    colorPredicted,
    pastData: pastData,
    predictedData: predictedData
  });

  return pastDates.reduce((a, b) => (a > b ? a : b));
};
