import MultiSelect, { MultiSelectOption } from "@components/Select/MultiSelect";
import { HorizonContext } from "@context";
import { useSectors } from "@services/api/horizon";
import { Brand } from "@services/api/model/horizon";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MAX_MARKET_SELECT_BRANDS } from "./constants";

const MarketBrandsSelector = () => {
  const { t } = useTranslation();

  const { currentCtx, saveCurrentCtx } = useContext(HorizonContext);

  const { data } = useSectors();

  const handleMarketBrandsChange = (event: any) => {
    const values = event.target.value;
    if (values.length <= MAX_MARKET_SELECT_BRANDS) {
      saveCurrentCtx({
        ...currentCtx,
        brandEquityCtx: {
          ...currentCtx.brandEquityCtx,
          marketBrandIds: values
        }
      });
    }
  };

  const handleMarketBrandsDelete = (deletedBrandId: string) => {
    const brandsFiltered = currentCtx.brandEquityCtx.marketBrandIds.filter(
      (brand_id) => brand_id !== deletedBrandId
    );
    saveCurrentCtx({
      ...currentCtx,
      brandEquityCtx: {
        ...currentCtx.brandEquityCtx,
        marketBrandIds: brandsFiltered
      }
    });
  };

  const brandsBySector: { [key: string]: Brand[] } = useMemo(() => {
    const brandsBySector: Record<string, Brand[]> = {};
    (data ?? []).map((sector) => {
      brandsBySector[sector.id] = sector.brands;
      return { ...sector, id: sector.id.toString() };
    });
    return brandsBySector;
  }, [data]);

  const marketBrandOptions: MultiSelectOption[] = useMemo(() => {
    return (brandsBySector[currentCtx.sectorId] ?? [])
      .filter((brand) => brand.id.toString() !== currentCtx.brandId)
      .map((brand) => ({
        ...brand,
        id: brand.id.toString()
      }));
  }, [currentCtx.sectorId, currentCtx.brandId, brandsBySector]);

  return (
    <MultiSelect
      id="market-brand-select"
      variant="outer-label"
      inputLabel={t("horizon.selectors.maket_brands")}
      value={currentCtx.brandEquityCtx.marketBrandIds}
      onChange={handleMarketBrandsChange}
      onDelete={handleMarketBrandsDelete}
      options={marketBrandOptions}
      onClose={() => {}}
      placeHolder={t("horizon.selectors.maket_brands_placeholder")}
    />
  );
};
export default MarketBrandsSelector;
