/** @jsxImportSource @emotion/react */
import HorizonRoutes from "@constants/horizonRouting";
import Routes from "@constants/routing";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import LayoutDefault from "@layouts/LayoutDefault";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import LinkButton from "./LinkButton";

export type LayoutHorizonElement = HTMLDivElement;

export type LayoutHorizonProps = {
  selectors?: JSX.Element | EmotionJSX.Element | undefined;
  className?: string;
};

const toFullPath = (module_path: string): string => {
  return `${Routes.Horizon}${module_path}`;
};

type HorizonTabsConfig = {
  path: string;
  label: string;
}[];

export const useHorizonTabsConfig = (): HorizonTabsConfig => {
  const { t } = useTranslation();
  return [
    {
      path: toFullPath(HorizonRoutes.Home),
      label: t("horizon.tabs.industry_trend")
    },
    {
      path: toFullPath(HorizonRoutes.BrandEquity),
      label: t("horizon.tabs.brand_equity_forecast")
    },
    {
      path: toFullPath(HorizonRoutes.Socioeconomic),
      label: t("horizon.tabs.socioeconomic_environment")
    },
    {
      path: toFullPath(HorizonRoutes.FunnelState),
      label: t("horizon.tabs.funnel_state")
    }
  ];
};

export const LayoutHorizon = React.forwardRef<
  LayoutHorizonElement,
  React.PropsWithChildren<LayoutHorizonProps>
>(({ selectors, children }, _) => {
  const location = useLocation();
  const horizonTabsConfig = useHorizonTabsConfig();

  const horizonTabs: JSX.Element[] = horizonTabsConfig.map((tabConfig) => (
    <LinkButton
      path={tabConfig.path}
      label={tabConfig.label}
      active={location.pathname === tabConfig.path}
    />
  ));

  return (
    <LayoutDefault title="Horizon" tabs={horizonTabs} selectors={selectors}>
      {children}
    </LayoutDefault>
  );
});

export default LayoutHorizon;
