import LayoutHorizon from "@layouts/LayoutHorizon";
import IndustryTrendCharts from "./components/Charts";
import IndustryTrendSelectors from "./components/Selectors";

const IndustryTrendView = () => {
  return (
    <LayoutHorizon selectors={<IndustryTrendSelectors />}>
      <IndustryTrendCharts />
    </LayoutHorizon>
  );
};

export default IndustryTrendView;
