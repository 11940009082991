import App from "@components/App";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import ReactDOM from "react-dom/client";
import "./index.css";
import keycloakClient from "./keycloak/keycloak";
import "@i18n/i18n";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ReactKeycloakProvider
    authClient={keycloakClient}
    initOptions={{ onLoad: "login-required", checkLoginIframe: false }}
    LoadingComponent={
      <Box className="flex w-screen h-screen items-center justify-center">
        <CircularProgress />
      </Box>
    }
  >
    <App />
  </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
