import Keycloak from "keycloak-js";
import { KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM, KEYCLOAK_URL } from "../config";
// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloakClient = new Keycloak({
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID,
  url: KEYCLOAK_URL
});
export default keycloakClient;
