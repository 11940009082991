import LineChart, { LineChartData } from "@dive.tv/dss-ui-graphs/LineChart";
import SwipeIcon from "@mui/icons-material/Swipe";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { SocioeconomicCategory } from "@services/api/model/horizon/Socioeconomics";
import LineChartTooltip from "@views/ViewHorizon/components/Charts/LineTooltip";

interface ChartCardProps {
  category: SocioeconomicCategory;
  data: LineChartData[];
}

const ChartCard = ({ category, data }: ChartCardProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        backgroundColor: "#ffffff",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        padding: "15px",
        height: "360px",
        position: "relative"
      }}
    >
      <Box
        sx={{
          position: isTablet ? "relative" : "absolute",
          left: isTablet ? 0 : 20,
          top: isTablet ? 0 : 10,
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Typography variant="h6">{category.label}</Typography>
      </Box>
      <Box
        sx={{
          overflowX: isTablet ? "scroll" : "none", // Estilos para "quitar" la barra de scroll
          scrollbarWidth: "none", // Estilos para "quitar" la barra de scroll - Firefox
          "&::-webkit-scrollbar": {
            display: "none" // Estilos para "quitar" la barra de scroll - Chrome, Safari y Edge
          },
          height: "inherit",
          marginBottom: 1
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: isMobile ? "680px" : "100%"
          }}
        >
          <LineChart
            data={data}
            responsiveLineProps={{
              animate: true,
              crosshairType: "bottom-left",
              curve: "linear",
              tooltip: LineChartTooltip,
              axisLeft: {
                tickSize: 5,
                tickPadding: 10
              },
              axisBottom: {
                tickRotation: 45,
                tickPadding: 10
              },
              markers: [
                {
                  axis: "y",
                  lineStyle: {
                    stroke: "#a8a8a8",
                    strokeWidth: 1
                  },
                  value: 0
                }
              ],
              margin: {
                top: 40,
                left: 40,
                right: isTablet ? 40 : 70,
                bottom: 70
              },
              legends: [
                {
                  anchor: isTablet ? "top-left" : "top-right",
                  direction: "row",
                  itemHeight: 10,
                  itemWidth: 150,
                  itemDirection: "left-to-right",
                  symbolSize: 15,
                  itemsSpacing: 50,
                  toggleSerie: true,
                  translateX: 0,
                  translateY: -40,
                  itemOpacity: 1,
                  symbolShape: (s) => (
                    <line
                      x1={s.x}
                      y1={s.y + 7} // Coloca la línea a distancia del y inicial para centrarla verticalmente
                      x2={s.x + 15} // Largo de la línea
                      y2={s.y + 7} // Mantener y1 e y2 iguales para que sea horizontal
                      stroke={s.fill} // El color de la línea es el mismo que el 'fill'
                      strokeWidth={4} // Grosor de la línea
                      strokeLinecap="round" // Hace los extremos de la línea redondeados
                    />
                  )
                }
              ]
            }}
          />
        </Box>
      </Box>
      {isMobile ? (
        <SwipeIcon
          sx={{
            position: "absolute",
            bottom: 12,
            opacity: 0.4,
            left: "50%",
            transform: "translate(-50%, 0)",
            color: theme.palette.primary.main
          }}
          fontSize="small"
        />
      ) : null}
    </Box>
  );
};

export default ChartCard;
