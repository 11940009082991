import { useKeycloak } from "@react-keycloak/web";
import { useQueries, useQuery } from "@tanstack/react-query";
import { getApiDefaultHeaders, getApiURL } from "../Base";
import { APIException } from "../model/APIException";
import { APIResponse } from "../model/APIResponse";
import { APIError } from "../model/horizon";
import {
  SocioeconomicCategory,
  SocioeconomicCategoryData
} from "../model/horizon/Socioeconomics";

const useSocioeconomics = () => {
  const { keycloak } = useKeycloak();

  const socioeconomicsCategories = useQuery({
    queryKey: ["tradingEconomicsCategories"],
    enabled: keycloak.authenticated && (keycloak.token ?? "").length > 0,
    queryFn: () =>
      fetch(`${getApiURL()}horizon-back/v1/trading_economics/categories`, {
        headers: { ...getApiDefaultHeaders(keycloak.token ?? "") }
      })
        .then((res) => {
          const response: APIResponse = {
            status: res.status,
            body: res.json()
          };
          return response;
        })
        .then((res) => {
          if (res.status === 200) {
            return res.body as SocioeconomicCategory[];
          }
          const apiError = res.body as APIError;
          throw new APIException(apiError.detail, res.status);
        })
  });

  const socioeconomicsDataQueries = useQueries({
    queries: (socioeconomicsCategories.data || []).map(
      (singleSocioeconomicData: any) => ({
        queryKey: ["singleTradingEconomicsData", singleSocioeconomicData.id],
        queryFn: async () => {
          try {
            const res = await fetch(
              `${getApiURL()}horizon-back/v1/trading_economics/data?category_id=${singleSocioeconomicData.id}`,
              {
                headers: { ...getApiDefaultHeaders(keycloak.token ?? "") }
              }
            );
            if (!res.ok) {
              const apiError = await res.json();
              throw new APIException(apiError.detail, res.status);
            }
            const data = await res.json();
            return data as SocioeconomicCategoryData[];
          } catch (error) {
            if (error instanceof APIException) {
              throw error;
            }

            // to fix de error type issue check if error has the structure we need.
            if (
              typeof error === "object" &&
              error !== null &&
              "message" in error
            ) {
              const errorMessage = (error as Error).message;
              const statusCode = 500; // default code err

              throw new APIException(errorMessage, statusCode);
            }

            // if it doesnt throw unknown err
            throw new APIException("Unknown error", 500);
          }
        },
        staleTime: Infinity,
        enabled: !!socioeconomicsCategories,
        context: { id: singleSocioeconomicData.id }
      })
    )
  });

  return {
    socioeconomicsCategories,
    socioeconomicsDataQueries
  };
};

export default useSocioeconomics;
