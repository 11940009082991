import Select, {
  SingleSelectOption
} from "@components/Select/SingleSelect/Select";
import { HorizonContext } from "@context";
import { SelectChangeEvent } from "@mui/material";
import { useYougovKPIs } from "@services/api/horizon";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

const KpiSelector = () => {
  const { t } = useTranslation();

  const { currentCtx, saveCurrentCtx } = useContext(HorizonContext);

  const { data } = useYougovKPIs();

  const handleKpiChange = (event: SelectChangeEvent) => {
    saveCurrentCtx({
      ...currentCtx,
      kpiId: event.target.value,
      brandEquityCtx: {
        ...currentCtx.brandEquityCtx
      }
    });
  };

  const youGovKpiOptions: SingleSelectOption[] = useMemo(() => {
    return (data ?? []).map((kpi) => ({ ...kpi, id: kpi.id.toString() }));
  }, [data]);

  return (
    <Select
      id="kpi-select"
      variant="outer-label"
      inputLabel={t("horizon.selectors.yougov_kpi")}
      value={currentCtx.kpiId}
      onChange={handleKpiChange}
      options={youGovKpiOptions}
    />
  );
};

export default KpiSelector;
