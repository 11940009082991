import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "@tanstack/react-query";
import { getApiDefaultHeaders, getApiURL } from "../Base";
import { APIException } from "../model/APIException";
import { APIResponse } from "../model/APIResponse";
import { APIError, IndustryTrendMetric } from "../model/horizon";

export interface IndustryTrendMetricsParams {
  sectorId: string;
  kpiId: string;
  brandIds: string[];
  timePeriod: string;
}

export const useIndustryTrendMetrics = ({
  sectorId,
  kpiId,
  brandIds,
  timePeriod
}: IndustryTrendMetricsParams) => {
  const { keycloak } = useKeycloak();
  const brandIdsStr = brandIds.map((id) => `${id}`).join(",");

  return useQuery({
    queryKey: [
      "industry_trend",
      "metrics",
      sectorId,
      kpiId,
      timePeriod,
      brandIdsStr
    ],
    enabled:
      keycloak.authenticated &&
      (keycloak.token ?? "").length > 0 &&
      sectorId.length > 0 &&
      brandIds.length > 0 &&
      kpiId.length > 0 &&
      timePeriod.length > 0,
    queryFn: () =>
      fetch(
        `${getApiURL()}horizon-back/v1/metrics/industry_trend?sector_id=${sectorId}&kpi_id=${kpiId}&time_period=${timePeriod}&brands_id=${brandIdsStr}`,
        {
          headers: { ...getApiDefaultHeaders(keycloak.token ?? "") }
        }
      )
        .then((res) => {
          const response: APIResponse = {
            status: res.status,
            body: res.json()
          };
          return response;
        })
        .then((res) => {
          if (res.status === 200) {
            return res.body as IndustryTrendMetric[];
          }
          const apiError = res.body as APIError;
          throw new APIException(apiError.detail, res.status);
        })
  });
};

export interface IndustryTrendMetricsExcelParams
  extends Partial<IndustryTrendMetricsParams> {}

export const useIndustryTrendMetricsExcel = ({
  sectorId,
  kpiId,
  brandIds,
  timePeriod
}: IndustryTrendMetricsExcelParams) => {
  const { keycloak } = useKeycloak();
  const brandIdsStr = brandIds?.map((id) => `${id}`).join(",");

  return useQuery({
    queryKey: [
      "industry_trend",
      "metrics",
      "excel",
      sectorId,
      kpiId,
      timePeriod,
      brandIdsStr
    ],
    enabled: false,
    queryFn: () =>
      fetch(
        `${getApiURL()}horizon-back/v1/metrics/industry_trend/excel?sector_id=${sectorId}&kpi_id=${kpiId}&time_period=${timePeriod}&brands_id=${brandIdsStr}`,
        {
          headers: { ...getApiDefaultHeaders(keycloak.token ?? "") }
        }
      )
        .then((res) => {
          const response: APIResponse = {
            status: res.status,
            body: res.blob()
          };
          return response;
        })
        .then((res) => {
          if (res.status === 200) {
            return res.body as Blob;
          }
          const apiError = res.body as APIError;
          throw new APIException(apiError.detail, res.status);
        })
  });
};
